<template>
  <div>

    <div style="background-color: #f5f6f8; min-height: 200px;">

      <div style="width: 100%; text-align: center; margin: 0 auto;">

        <template v-if="pageLoaded">
          <div style="  margin: -20px auto 0; text-decoration: none!important;">
            <div style="border-top:1px solid #e1e1e1;"></div>
            <div v-if="style_type !== 'list'" :class="['cardA1', 'generalCard', 'card_'+style_type, color]" :style="'width:100%!important; height: 220px;  background-size: cover ; background-repeat:no-repeat; background-image: url('+image_url+');'">
              <h1 v-if="style_type === 'bottom_title'" class="bottom_text_placeholder" style="margin: 0">
                <p class="subheader">{{subheader}}</p>
                <p class="title" style="color: #2D3640; margin: 0" v-html="header"></p>
                <p class="subheader2">{{subheader2}}</p>
              </h1>
              <h1 v-if="style_type !== 'long_title'" style="margin: 0">
                <p class="subheader">{{subheader}}</p>
                <p class="title" style="color: #2D3640; margin: 0" v-html="header"></p>
                <p class="subheader2">{{subheader2}}</p>
              </h1>
            </div>
            <div v-if="style_type === 'list'" :class="['generalCard', 'card_'+style_type, color]">
              <p class="subheader">{{subheader}}</p>
              <p class="title">{{header}}</p>
            </div>
          </div>



          <div class=" blogContentMobile" style=" text-align: left">



            <div style=" margin: 0 16px; font-size: 19px">

              <div class="blog-bread" v-if="blog_sub_category_id">
                <div style="display: flex">
                  <router-link to="/blog" class="item">Tüm Yazılar</router-link>
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
                  <router-link :to="'/blog/kategori/'+blog_category_slug"  class="item">{{ blog_category_name }}</router-link>
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
                  <router-link :to="'/blog/kategori/'+blog_category_slug+'/'+blog_sub_category_slug" class="item">{{blog_sub_category_name}}</router-link>
                </div>
                <div style="margin-bottom: 10px; margin-top: 10px">
                  <router-link  :to="'/blog/kategori/'+blog_category_slug+'/'+label.slug" v-for="(label, lindex) in labels" :key="lindex" style="padding-right:14px;color: #2cad87; font-size: 13px; font-weight: 600">#{{ label.title }}</router-link>
                </div>

              </div>



              <div style="font-weight:400; line-height: 1.9;">
                <h1 v-if="style_type === 'long_title'" class="long-main-title">{{header}}</h1>
                  <span  v-for="(list, index) in htmlList" :key="index">

                  <div style="width: 100%; overflow: hidden" v-if="list.split(':portfolio:')[1]">
                      <Portfolio :uuid="list.split(':portfolio:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':gig:')[1]" style="margin:  0 -8px">
                      <List :slug="actualPath" :impression-u-u-i-d="impressionUUID" type="gig" :list="list.split(':gig:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':username:')[1]">
                      <List :slug="actualPath" :impression-u-u-i-d="impressionUUID" type="username" :list="list.split(':username:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':imageText:')[1]">
                       <template v-if="list.split(':imageText:')[1].split('--')">
                        <p class="caption-link">
                        {{  list.split(':imageText:')[1].split('--')[0] }}
                            <template v-if="list.split(':imageText:')[1].split('--')">
                               <router-link :to="'/'+list.split(':imageText:')[1].split('--')[1]" class="caption-a">
                             {{list.split(':imageText:')[1].split('--')[1]}}
                           </router-link>
                          </template>
                       </p>
                       </template>
                      <template v-else>
                         <p class="caption-link">{{ list.split(':imageText:')[1]}}</p>
                      </template>

                  </div>

                    <p v-else v-html="list"></p>

                </span>
              </div>


            </div>


            <div class="hiddendiv"></div>
            <div class="freelancer-list">
              <div v-for="(list, index) in renderedLists" :key="index" class="freelancer-list-container">
                <div class="freelancer-list-div">
                  <div :class="'freelancer-list-div-div_'+list.type">
                    <router-link  :to="list.action.url">
                      <img v-if="list.type === 'gig'" class="freelancer-list-avatar" :src="list.image_url"/>
                      <img v-if="list.type === 'user'" class="freelancer-list-avatar_s" :src="list.image_url"/>
                    </router-link>
                  </div>
                  <div class="freelancer-list-second-flex">
                    <router-link  :to="list.action.url">
                      <p v-if="list.type === 'user'" class="freelancer-list-username">{{list.title}}</p>
                      <p v-if="list.type === 'gig'" class="freelancer-list-user-title" style="padding-top: 6px;">{{list.title}}</p>
                      <p v-if="list.type === 'user'" class="freelancer-list-user-title_s">{{list.subtitle}}</p>
                      <p v-if="list.type === 'gig'" style="font-size: 14px; font-weight: 500; color: #2d3640; padding-top: 4px;">{{list.subtitle}}</p>
                    </router-link>
                  </div>
                </div>
                <div v-if="index !== renderedLists.length - 1 && list.type === 'gig'" class="freelancer-list-border" ></div>
                <div v-if="index !== renderedLists.length - 1 && list.type === 'user'" class="freelancer-list-border_s" ></div>
              </div>
            </div>


            <div v-if="footer_div.sellerName !== ''" class="hiddendiv20"></div>
            <div v-if="footer_div.sellerName !== ''" style="padding-top:10px;height: 330px; background-color: #eff1f3;">
              <div style="margin:0px auto; width:100%;text-align:center;">
                <p v-if="footer_div.external_id_text" style="font-size: 14px; padding-top: 5px; font-weight:400;color: #2d3640">{{ footer_div.external_id_text }}</p>
                <div v-else class="hiddendiv20"></div>
                <router-link :to="footer_div.action.url">
                  <img style="height: 124px; width: 124px; border-radius: 20px; margin-top: 24px; " :src="footer_div.seller_avatar_url">
                </router-link>
                <p style="color: #007aff; margin-top: 16px; font-weight: 600; font-size: 24px;">{{footer_div.sellerName}}</p>
                <p style="line-height: 19px; height: 20px;  margin-top: 8px; margin-bottom: 16px;
    font-size: 14px;

    color: rgb(139, 149, 161);">{{footer_div.seller_title}}</p>

                <div style="margin-top: 32px;">

                  <router-link :to="footer_div.action.url" class="nounderlinehover" style=" background-color: #007aff;color: white;  font-weight:600;border-radius: 120px; font-size: 16px; cursor: pointer;  padding: 5px 23px 7px 23px">
                    {{footer_div.action.text}}
                  </router-link>

                </div>
              </div>

            </div>



            <div class="hiddendiv"></div>

            <div style="text-align: center; margin-bottom: 40px;">
              <router-link to="/blog" class="blogGo">
                Tüm Yazılara Git
              </router-link>
            </div>

            <div style="text-align: center; margin-bottom: 10px;">
                <span @click="showShareButtonDetail" v-if="showShareButton" class="blogShare">
                  <img style="display: inherit!important;width: 16px; margin-right: 5px;" src="https://gcdn.bionluk.com/site/icon/iosShare.png"/>
                  Yazıyı Paylaş
                </span>
              <div v-if="!showShareButton" @click="clickedShareButton">
                <social-sharing :url="url" inline-template>
                  <div style="margin-top: 20px;">
                    <network network="facebook" style="cursor: pointer;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/facebook-box.svg'"/>
                    </network>
                    <network network="linkedin" style="cursor: pointer; margin-left: 10px;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/linkedin-box.svg'"/>
                    </network>
                    <network network="twitter" style="cursor: pointer; margin-left: 10px;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/twitter-box.svg'"/>
                    </network>
                  </div>
                </social-sharing>
              </div>
            </div>


          </div>


        </template>
        <div v-else class="loader" style="text-align: center; margin:20px auto;"></div>
      </div>

    </div>
  </div>
</template>

<script>
  import Portfolio from "../../../../components/todayItems/portfolio_mobile.vue";
  import List from "../../../../components/todayItems/list_mobile.vue";

  export default {
    name: "src-pages-body-staticContents-blog-detail-v1_mobile",
    components: {Portfolio, List},
    data() {
      return {

        impressionUUID:null,

        screenWidth: (window.innerWidth) > 414 ? 414 : window.innerWidth,
        screenHeight: (((window.innerWidth) > 414 ? 414 : window.innerWidth) * 1.23),

        showShareButton:true,
        pageLoaded: false,
        blogID: null,
        name: null,
        content: null,
        brief: null,
        commentsCount: null,
        createdAt: null,
        url: window.location.href,
        comments: [],
        newComment: '',
        blogLang: null,
        header:null,
        header_m:null,
        subheader: null,
        subheader2: null,
        image_url: '',

        style_type:null,
        size_type:null,
        color:null,

        renderedLists:[],
        footer_div: {
          sellerName:"",
          seler_id:"",
          seller_avatar_url:"",
          seller_title:"",
          action:{
            text:"",
            action:"",
            url:""
          }
        },
        htmlList:[],
        blog_category_id:null,
        blog_category_name:null,
        blog_category_slug:null,
        blog_sub_category_id:null,
        blog_sub_category_name:null,
        blog_sub_category_slug:null,
        labels:[]

      };
    },
    methods: {


	    clickedShareButton(){
		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'blog share buttons'});
	    },

      showShareButtonDetail(){

        this.showShareButton = false;
      },
      getBlog() {
        this.api.content.getBlogv2(this.actualPath)
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;
            if (result.success) {
              this.blogID = result.data.topic.blog_id;
              this.name = result.data.topic.name;
              this.content = result.data.topic.description;
              this.brief = result.data.topic.brief;
              this.image_url = result.data.topic.image_url_list;
              this.commentsCount = result.data.topic.comment_count;
              this.createdAt = Number(result.data.topic.created_at);

              this.renderedLists = result.data.topic.renderedLists;
              this.style_type = result.data.topic.style_type;
              this.size_type = result.data.topic.size_type;
              this.color = result.data.topic.color;
              this.header = result.data.topic.header;
              this.header_m = result.data.topic.header_m;
              this.subheader = result.data.topic.subheader;
              this.subheader2 = result.data.topic.subheader2;
              this.footer_div = result.data.topic.footer_div;
              this.htmlList = result.data.topic.htmlList;
              this.comments = result.data.blog_topic_comments;
              this.comments.forEach(comment => {
                comment.created_at  = this.calculateElapsedTime(Number(comment.created_at))
              });

              this.blog_category_id = result.data.topic.blog_category_id;
              this.blog_category_name = result.data.topic.blog_category_name;
              this.blog_category_slug = result.data.topic.blog_category_slug;
              this.blog_sub_category_id = result.data.topic.blog_sub_category_id;
              this.blog_sub_category_name = result.data.topic.blog_sub_category_name;
              this.blog_sub_category_slug = result.data.topic.blog_sub_category_slug;
              this.labels = result.data.topic.labels;

              this.blogLang = result.data.topic.lang;
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.setFacebookMetaProperties(result.data.meta.facebookMeta);

              this.Helper.trackEvents.pageView(this.Helper.blogDetailPageProps(result.data.topic.blog_id, this.$store.state.routerParams[0]));
            } else {
              this.$toasted.global.errorToast({description: result.message});

              let meta = document.createElement('meta');
              meta.name = "render:status_code";
              meta.content = "301";
              document.getElementsByTagName('head')[0].appendChild(meta);

              let meta3 = document.createElement('meta');
              let meta3content = "Location:https://bionluk.com" + result.data.redirect_url;
              meta3.setAttribute("name", "render:header");
              meta3.setAttribute("content", meta3content);
              document.getElementsByTagName('head')[0].appendChild(meta3);


              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      saveBlogComment() {
        this.saveComment();
        this.api.content.saveBlogComment(this.blogID, this.newComment, this.blogLang)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.newComment = "";

            } else {
              this.revertComment();
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }

          })
          .catch(err => {
            this.revertComment();
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      saveComment() {
        let savedComment = {
          user: {
            username: this.user.username,
            avatar_url: this.user.avatar_url
          },
          comment: this.newComment,
          created_at: this.calculateElapsedTime(+new Date() - 1000)
        };

        this.comments.push(JSON.parse(JSON.stringify(savedComment)));
      },

      revertComment() {
        this.comments.pop();
        this.newComment = "";
      },

    },
    computed: {
      actualPath() {
        return this.$store.state.routerParams[0];
      }
    },
    watch: {
      actualPath(newValue, oldValue) {
        if (newValue) {
          this.pageLoaded = false;
          this.getBlog();
        }
      }
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID()
      this.pageLoaded = false;
      this.getBlog();
    }
  }
</script>

<style scoped lang="scss">


.caption-link{
  font-size:15px; text-align: center; margin-top: 10px; font-weight: 300;
  .caption-a{
    color: #fe5b5c;
    text-decoration: none;
    font-weight: 600;
    &:hover{
      text-decoration: underline;
    }
  }
}



.blogInside_x{
  font-size: 18px;
  line-height: 2;
  color: #5e6b79;
  margin-top: 30px;
}

.blog-bread{

  margin-top: 20px;
  margin-bottom: 10px;

  .item{
    font-size: 14px;
    text-align: left;
    color: #8b95a1;
    text-decoration: none!important;
    font-weight: 400;

  }
  img{
    margin-left: 6px; margin-right: 5px;
  }
}

.long-main-title{
  text-align: left;
  margin: 5px 0 15px;
  font-weight: 600;
  font-size: 27px;
  color: #2D3640;
  line-height: 35px;

}


  .blogContent{

    line-height: 1.55;

   font-size: 16px; width: 100%; margin: 0 auto; text-align: left;min-height: 400px; background: #FFFFFF; margin-bottom: 50px;
  }



  .blogContentYorum{
    background-color: #ffffff;
    border-bottom: solid 1px #eaedf2;line-height: 1.3; color: #707070; font-size: 14px;width: 100%; margin:0 auto;background: #FFFFFF;
  }

  h2 {
    font-size: 10px!important;
    margin: 100px!important;
  }




  .freelancer-list{
    margin-right: 14px; margin-left: 14px
  }

  .freelancer-list-div{
    display: flex; height: 100px;
  }

  .freelancer-list-div-div_user{
    align-self: center;
    width: 200px;

  }

  .freelancer-list-div-div_gig{
    align-self: center;
    width: 250px;

  }

  .freelancer-list-avatar{
    height:70px;  border:1px solid #dde3eb;border-radius: 4px;
  }

  .freelancer-list-avatar_s{
    height:70px;  border:1px solid #dde3eb;border-radius: 12px;
  }


  .freelancer-list-second-flex{
    margin-left: 16px; height: 80px; align-self: center;
  }

  .freelancer-list-username{padding-top: 5px;
    line-height:36px;font-size: 24px; font-weight: 400; color: rgb(36, 36, 36);
  }

  .freelancer-list-user-title{
    line-height:15px; font-size: 15px; font-style: italic; color: rgb(139, 149, 161);
  }

  .freelancer-list-border{
    margin-left:130px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }

  .freelancer-list-border_s{
    margin-left:110px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }


  .freelancer-list-user-title_s{
    line-height:19px; font-size: 15px; font-style: italic; color: rgb(139, 149, 161);
  }


  .blogGo{
    background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
    color:#007aff;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;

  }

  .blogGo:hover{
    background-color: #efeff4;
  }

  .blogShare{
    background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
    color:#007aff;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

  }

  .blogShare:hover{
    background-color: #efeff4;
  }




  .generalCard{
    text-align: left;
    position: relative;



    background-color: rgb(255, 255, 255);

  }


  .generalCard:hover{
    text-decoration: none!important;
  }




  .cardA1{
    background-position: center;
  }



  .card_bold .title{

    font-size: 48px; width: 250px;

    bottom: 80px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);


  }


  .card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:20px;line-height: 1.56;
    position: absolute;
    bottom: 48px;
    left: 30px;


  }


  .card_up_title .title{


    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;


  }


  .card_up_title .subheader{


    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;

  }



  .card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;

  }



  .card_list .title{



    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;

  }


  .card_list .subheader{

    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;
  }



  .card_list .subheader2{

    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;
  }



  .bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 20px;
    right: 20px;
  }


  .card_bottom_title .subheader{


    z-index: 1;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 32px;

  }


  .card_bottom_title .title{

    font-size: 32px;
    font-weight: 600;
    line-height: 50px;

  }




  .card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 18px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }




  .white{
    color: #fff;
  }

  .black{
    color: #242424;
  }







</style>
