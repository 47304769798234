<template>
  <div>
    <loader v-if="loader"></loader>
    <div v-else class="portfolio-box">
      <div class="head-section">
        <router-link
          @contextmenu.native="handleGlobalClick($store.state.componentMap.page)"
          @click.native="handleGlobalClick($store.state.previousComponentPage)"
          :to="'/'+portfolio.user.username">
          <img class="avatar" :src="portfolio.user.avatar_url">
        </router-link>
        <div style=" margin-left: 6px; display: flex; height:40px; flex-direction: column; justify-items: center">
          <p class="title">{{portfolio.name}}</p>
          <p class="sub-title" v-if="portfolio.rating_user.id">{{portfolio.user.username}}, <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page)"
            @click.native="handleGlobalClick($store.state.previousComponentPage)"
            :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
          <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
        </div>
      </div>
      <router-link class="portfolio-img"  @contextmenu.native="handleGlobalClick($store.state.componentMap.page)"
                   @click.native="handleGlobalClick($store.state.previousComponentPage)"
                   :to="'/freelancer-vitrin/'+portfolio.uuid">
        <div v-if="portfolio.cover.file_type === 'audio'" class="sound-icon-container">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
        </div>
        <div v-if="portfolio.cover.file_type === 'video'" class="sound-icon-container">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
        </div>
        <img :src="portfolio.cover.image_url">
      </router-link>
      <div class="foot-section">
        <div style="display: flex">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
          <span>{{portfolio.fav_count}}</span>
          <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
          <span>{{portfolio.comment_count}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import Loader from "../toolbox/loader.vue";


  export default {
    name: "src-pages-components-shared-navFooter",
    components:{Loader},
    props: ["uuid"],
    data() {
      return {
        loader:true,
        portfolio:{
          rating_user:{},
          user:{
            sellerRating:{}
          },
          likeStatus:{},
          items:[],
          cover:{},
          comments:{}
        },

      };
    },

    methods:{

      showPortfolioDetailModal(uuid) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid}});
      },
    },

    created() {

      this.api.seller.getPortfolio(this.uuid)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            this.portfolio = result.data.portfolio;
            this.loader = false;
          } else {
            this.$toasted.global.errorToast({description: result.message});
            this.loader = false;
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.loader = false;
        });


    }
  }
</script>

<style scoped lang="scss">
.portfolio-box{
  margin: 0 auto;

  width: 277px;
  height: 274px;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;

  .head-section{
    display: flex;
    align-items: center;
    height: 66px;
    margin-left: 11px;
    .title{
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px;
      display: block;
    }
    .avatar{
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .sub-title{
      margin-top: 1px;
      height: 20px;
      font-size: 12px;
      font-weight: 300;
      color: #8b95a1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 210px;
      a{
        font-size: 12px;
        font-weight: 600;
        color: #fd4056;
        &:hover{
          text-decoration: underline;
          color: #fd4056!important;
        }
      }
    }
  }

  .portfolio-img{

    position: relative;
    cursor: pointer;
    height: 158px;
    img{
      width: 100%;
    }
  }

  .foot-section{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    height: 48px;

    font-size: 16px;
    font-weight: 600;
    color: #8b95a1;

    span{
      font-size: 16px;
      font-weight: 600;
      color: #8b95a1;
      padding-left: 5px;
      padding-right: 15px;
      line-height: 0.9;

    }
    .like-icon /deep/ {
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #bfc8d2!important;
      }
    }

    .trash-icon /deep/ {
      cursor: pointer;
      width: 20px;
      height: 20px;
      path {
        fill: #bfc8d2!important;
        &:hover{
          fill: #2d3640!important;
        }
      }
    }

    .trash-icon:hover{
      path {
        fill: #2d3640!important;
      }
    }

    .edit-icon /deep/ {
      cursor: pointer;
      width: 20px;
      height: 20px;
      path {
        fill: #bfc8d2!important;
        &:hover{
          fill: #2d3640!important;
        }
      }
    }
    .edit-icon:hover{
      path {
        fill: #2d3640!important;
      }
    }

  }
}




  .portfolio-modal-review-main {
    width: 100%;
  }




  .loader-div {
    position: relative;
    width: 100%;
    height: 100%;
  img {
    width: 100%;
    object-fit: cover
  }

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    width: 60px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
  img {
    height: 60px;
    width: 60px;
  }
  }


</style>
